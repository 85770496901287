import { useState } from "react";

export default function FAQ() {
  const [activeMenu, setActiveMenu] = useState(null);
  return (
    <div className="flex w-full max-w-7xl flex-col">
      <div className="flex border-b border-purple flex-col">
        <button
          onClick={() =>
            activeMenu === 0 ? setActiveMenu(null) : setActiveMenu(0)
          }
          className="flex px-4 py-3 flex-1 text-xl md:text-2xl rounded-t-lg text-white font-semibold"
        >
          When is the Mint Date?
        </button>
        {activeMenu === 0 && (
          <div className="text-white px-4 py-3 text-xl flex flex-1">
            Contracts, art, rewards are ready! We will plan on launching
            sometime after EVMOS is deployed again!
          </div>
        )}
      </div>
      <div className="flex border-b border-purple flex-col">
        <button
          onClick={() =>
            activeMenu === 1 ? setActiveMenu(null) : setActiveMenu(1)
          }
          className="flex px-4 py-3 flex-1 text-xl md:text-2xl rounded-t-lg text-white font-semibold"
        >
          How do I Mint?
        </button>
        {activeMenu === 1 && (
          <div className="text-white px-4 py-3 text-xl flex flex-1">
            Click on the “connect” button to link your MetaMask wallet to the
            site. This will set up your wallet in the right network. You can now
            press mint and buy your Orbital Ape! Mint as much as you want!
          </div>
        )}
      </div>
      <div className="flex border-b border-purple flex-col">
        <button
          onClick={() =>
            activeMenu === 2 ? setActiveMenu(null) : setActiveMenu(2)
          }
          className="flex px-4 py-3 flex-1 text-xl md:text-2xl rounded-t-lg text-white font-semibold"
        >
          What is the mint price?
        </button>
        {activeMenu === 2 && (
          <div className="text-white px-4 py-3 text-xl flex flex-1">
            As EVMOS currently has an undefined value, we will announce the
            price once EVMOS is live and hits Dexes!
          </div>
        )}
      </div>
      <div className="flex border-b border-purple flex-col">
        <button
          onClick={() =>
            activeMenu === 3 ? setActiveMenu(null) : setActiveMenu(3)
          }
          className="flex px-4 py-3 flex-1 text-xl md:text-2xl rounded-t-lg text-white font-semibold"
        >
          How can I Trade my NFTs?
        </button>
        {activeMenu === 3 && (
          <div className="text-white px-4 py-3 text-xl flex flex-1">
            As part of the Orbital Apes roadmap, we will release our own NFT
            marketplace as soon as we launch the collection. You can start
            trading and earning rewards as soon as Orbital Apes mint is open!
          </div>
        )}
      </div>
      <div className="flex border-b border-purple flex-col">
        <button
          onClick={() =>
            activeMenu === 4 ? setActiveMenu(null) : setActiveMenu(4)
          }
          className="flex px-4 py-3 flex-1 text-xl md:text-2xl rounded-t-lg text-white font-semibold"
        >
          Is there a whitelist?
        </button>
        {activeMenu === 4 && (
          <div className="text-white px-4 py-3 text-xl flex flex-1">
            Yes! Whitelist spots will be given to the community through
            partnerships with other dApps in the EVMOS ecosystem, and to our
            most loyal community members. Details will be announced through
            socials.
          </div>
        )}
      </div>
      <div className="flex border-b border-purple flex-col">
        <button
          onClick={() =>
            activeMenu === 5 ? setActiveMenu(null) : setActiveMenu(5)
          }
          className="flex px-4 flex-start py-3 flex-1 text-xl md:text-2xl text-left rounded-t-lg text-white font-semibold"
        >
          How many Orbital Apes can I mint per transaction? Is there a limit per
          wallet?
        </button>
        {activeMenu === 5 && (
          <div className="text-white px-4 py-3 text-xl flex flex-col gap-4 flex-1">
            <div className="flex flex-col">
              <span className="font-medium">Whitelist mint</span>
              There will be a limit of 1 mint per wallet.
            </div>
            <div className="flex flex-col">
              <span className="font-medium">Public mint</span>
              There will be a limit of 5 tokens per transaction. There will be
              no limit per wallet.
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

import { convertAmountForFrontend } from "../../utils/network";
import { useState } from "react";
import { EVMOS_DENOMINATION, FEE, VALIDATOR } from "../../utils/network";
import { signCosmosAndBroadcast } from "../../utils/wallets/signer";
import { prepareAccount } from "../staking/utils";
import { createTxMsgBeginRedelegate } from "@tharsis/transactions";
import { ethers } from "ethers";

async function executeDelegate(provider, wallet, pubkey, amount, source) {
    const values = await prepareAccount(provider, wallet, pubkey);
    if (values === null) {
      return;
    }
  
    const params = {
      validatorSrcAddress: source,
      validatorDstAddress: VALIDATOR,
      amount: ethers.utils.parseEther(amount).toString(),
      denom: EVMOS_DENOMINATION,
    };
  
    const msg = createTxMsgBeginRedelegate(values.chain, values.sender, FEE, "", params);
  
    const res = await signCosmosAndBroadcast(
      provider,
      values.chain,
      values.sender,
      msg
    );
    return res;
  }

export default function RestakeRow({ validatorData, data, provider, wallet, pubkey }) {
  const [amount, setAmount] = useState(0);

  return (
    <div className="flex w-full border-t border-purple px-2 pt-3 justify-between text-white">
      <span>{validatorData?.description?.moniker}</span>
      <span>{convertAmountForFrontend(data.balance.amount)} EVMOS</span>
      <div className="flex gap-2">
        <input
          className="border-gray w-44 outline-none bg-dark rounded px-2"
          type="text"
          placeholder="0"
          value={amount}
          onChange={(e) => {
            setAmount(e.target.value);
          }}
        />
        <button
          className="bg-dark px-4 rounded"
          onClick={() => {
            if (data.balance.amount) {
              setAmount(convertAmountForFrontend(data.balance.amount));
            }
          }}
        >
          MAX
        </button>
      </div>
      <button 
              onClick={async () => {
                await executeDelegate(provider, wallet, pubkey, amount, validatorData?.operator_address);
              }}
      className="bg-dark px-4 rounded">Restake</button>
    </div>
  );
}

import Logo from "../assets/placeholders/logo.png";
import Discord from "../assets/icons/discord.svg";
import Twitter from "../assets/icons/twitter.svg";
function MobileHeader() {
  return (
    <header
      style={{ backgroundColor: "#0C0C0C" }}
      className="flex px-8 w-full sticky z-50 top-0 shadow-sm justify-center "
    >
      <nav className="flex   items-center justify-between w-full">
        <div className="flex  items-center justify-between gap-3 py-0  w-full ">
          <a href="/">
            <img alt="logo" className="h-16 xl:mr-28" src={Logo} />
          </a>
          <div className="flex items-center gap-3 justify-end ">
            <div className="mr-10 md:flex hidden  items-center gap-1 justify-end ">
              <a
                href="/validator"
                className="text-white text-lg flex items-center h-10 mdd:px-3 px-2 hover:bg-gray hover:bg-opacity-10 font-semibold"
              >
                Validator
              </a>
            </div>
            <div className="flex self-center gap-4">
              <a
                href="https://discord.gg/orbitalapes "
                className=" w-7 h-7 rounded-full"
              >
                <img className="w-full" alt="discord" src={Discord} />
              </a>
              <a
                href="https://twitter.com/OrbitalApes"
                className=" w-6 h-6 rounded-full"
              >
                <img className="w-full" alt="twitter" src={Twitter} />
              </a>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default MobileHeader;

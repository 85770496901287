import { connectKeplr } from "../../utils/wallets/keplr";
import { connectMetamask } from "../../utils/wallets/metamask";
import Metamask from "../../assets/icons/metamask.svg";
import Kplr from "../../assets/icons/keplr.png";
import { NO_WALLET } from "../../utils/wallets/constants";
import { evmosToEth } from "@tharsis/address-converter";

const WalletConnect = ({ setProvider, setWallet, setPubkey, wallet }) => {
  return (
    <div className="gap-3 flex">
      <button
        className="border gap-3 items-center py-2 flex px-3 rounded-md border-darkGray"
        onClick={async () => {
          await connectMetamask(setProvider, setWallet, setPubkey);
        }}
      >
        <img src={Metamask} className="h-7" alt="metamask" />
        {(wallet === NO_WALLET || !wallet) &&
        <>
        Connect Metamask
        </>
        }
        {(wallet !== NO_WALLET && wallet) &&
        <>
        Connected {evmosToEth(wallet).substring(0,8)}...
        </>
        }
      </button>
      <button
      disabled={true}
        className="border opacity-40 gap-3 items-center py-2 flex px-3 rounded-md border-darkGray"
        onClick={async () => connectKeplr(setProvider, setWallet, setPubkey)}
      >
        <img src={Kplr} className="h-7" alt="metamask" />
        Connect Keplr
      </button>
    </div>
  );
};

export default WalletConnect;

import Cover from "react-video-cover";
import ArrowDown from "../../assets/icons/arrowDown.svg";
import Discord from "../../assets/icons/discord.svg";
import Twitter from "../../assets/icons/twitter.svg";
import Add from "../../assets/icons/add2.svg";
import Minus from "../../assets/icons/delete.svg";
import Cosmos from "../../assets/icons/cosmos.svg";
import Secret from "../../assets/icons/secret.svg";
import Terra from "../../assets/icons/terra.svg";
import Orb from "../../assets/icons/Orb.svg";
import FAQ from "./FAQ";

const style = {
  width: "100vw",
  height: "calc(100vh - 65px)",
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: -1,
};

function Home() {
  const videoOptions = {
    src: "https://d37zfww822zd1w.cloudfront.net/orbitalApesLanding/Cover.mp4",
    autoPlay: true,
    muted: true,
    loop: false,
    playsInline: true,
  };

  return (
    <div className="flex items-center flex-col">
      <div className="flex relative">
        <div className="absolute" style={style}>
          <Cover videoOptions={videoOptions} remeasureOnWindowResize />
        </div>
        <div
          style={{ width: "100vw", height: "calc(100vh - 65px)" }}
          className="flex items-center text-white flex-col"
        >
          <div className="flex bottom-0 items-center absolute gap-2 flex-col">
            <div className="flex  gap-6 items-center text-5xl md:text-7xl font-semibold  flex-col">
              <div className="flex items-center gap-2 flex-col">
                <div className="flex gap-2.5">
                  <span
                    data-aos="fade-down"
                    data-aos-duration="400"
                    data-aos-delay="100"
                  >
                    WELCOME
                  </span>
                  <span
                    data-aos="fade-down"
                    data-aos-duration="400"
                    data-aos-delay="500"
                  >
                    TO
                  </span>
                </div>

                <span
                  data-aos="fade-down"
                  data-aos-duration="400"
                  data-aos-delay="1000"
                >
                  ORBITAL APES
                </span>
              </div>
            </div>
            <img
              data-aos="fade-down"
              data-aos-offset="0"
              data-aos-delay="2200"
              alt="arrow"
              className="w-16 animate-bounce"
              src={ArrowDown}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-center bg-black px-7 py-32 w-full">
        <div className="flex md:flex-row gap-10 flex-col max-w-7xl justify-between w-full">
          <div className="flex text-white gap-8 flex-col">
            <span
              data-aos="fade-up"
              className="text-5xl md:text-7xl text-center md:text-right font-semibold "
            >
              The Future
              <br />
              Starts Now
            </span>
            <span
              data-aos="fade-right"
              className="max-w-md self-center text-center md:text-right text-lg"
            >
              Orbital Apes is a collection of 10,000 NFTS coming to EVMOS. Each
              Orbital Ape created from a high quality 3D generation consisting
              of over 200 different attributes and 4 unique generations. Join
              our community to expand your network, earn rewards, and play
              exclusive games.
            </span>
          </div>
          <div className="flex self-center flex-wrap justify-center max-w-lg md:max-w-xl gap-1">
            <img
              data-aos="fade-down-right"
              className="md:w-44 w-36 h-36 md:h-44"
              alt="sampleape"
              src={
                "https://d37zfww822zd1w.cloudfront.net/orbitalApesLanding/1.png"
              }
            />
            <img
              data-aos="fade-down"
              className="md:w-44 w-36 h-36 md:h-44"
              alt="sampleape"
              src={
                "https://d37zfww822zd1w.cloudfront.net/orbitalApesLanding/2.png"
              }
            />
            <img
              data-aos="fade-down-left"
              className="md:w-44 w-36 h-36 md:h-44"
              alt="sampleape"
              src={
                "https://d37zfww822zd1w.cloudfront.net/orbitalApesLanding/3.png"
              }
            />
            <img
              data-aos="fade-up-right"
              className="md:w-44 w-36 h-36 md:h-44"
              alt="sampleape"
              src={
                "https://d37zfww822zd1w.cloudfront.net/orbitalApesLanding/4.png"
              }
            />
            <img
              data-aos="fade-up"
              className="md:w-44 w-36 h-36 md:h-44"
              alt="sampleape"
              src={
                "https://d37zfww822zd1w.cloudfront.net/orbitalApesLanding/5.jpg"
              }
            />
            <img
              data-aos="fade-left"
              className="md:w-44 w-36 h-36 md:h-44"
              alt="sampleape"
              src={
                "https://d37zfww822zd1w.cloudfront.net/orbitalApesLanding/6.jpg"
              }
            />
          </div>
        </div>
      </div>
      <div
        style={{
          backgroundImage: `url(https://d37zfww822zd1w.cloudfront.net/orbitalApesLanding/background1.png)`,
        }}
        className="flex  w-full bg-cover  self-center flex-col"
      >
        <div className="gap-2  flex items-center flex-col bg-gradient-to-b from-black to-black via-transparent w-full">
          <div className="gap-2 pt-32 text-white flex px-8 justify-center bg-black bg-opacity-70 w-full">
            <div className="gap-2 text-white flex max-w-7xl items-center justify-between w-full">
              <img
                data-aos="fade-right"
                className="md:flex hidden h-100"
                alt="sampleape"
                src={
                  "https://d37zfww822zd1w.cloudfront.net/orbitalApesLanding/mint1.png"
                }
              />
              <div className="flex pb-20 items-center gap-10 flex-col">
                <div className="flex items-center gap-2 flex-col">
                  <span
                    data-aos="fade-up"
                    className="text-5xl md:text-7xl text-right font-semibold "
                  >
                    Mint
                  </span>
                  <span
                    data-aos="fade-up"
                    className="text-center text-2xl md:text-3xl"
                  >
                    You don't want to miss out, board the
                    <br />
                    rocket ship before it launches!
                  </span>
                </div>
                <div className="flex items-center gap-4 flex-col">
                  <span
                    data-aos="fade-up"
                    className="font-semibold text-3xl md:text-4xl"
                  >
                    0/10,000 Minted
                  </span>
                  <div data-aos="fade-up" className="flex gap-3">
                    <div className="bg-black rounded-full border-2 justify-between items-center flex p-2 py-0 border-purple bg-opacity-40">
                      <img
                        alt="slector"
                        className="md:w-7 md:h-7 w-5 h-5"
                        src={Minus}
                      />
                      <span className="text-center text-white text-xl md:text-2xl font-medium w-14">
                        1
                      </span>
                      <img
                        alt="slector"
                        className="md:w-7 md:h-7 w-5 h-5"
                        src={Add}
                      />
                    </div>
                    <a
                      href="/collections"
                      className="border-purple border-2 bg-black bg-opacity-40 shadow font-medium md:text-lg px-5 py-2 rounded-full  text-white"
                    >
                      Mint (coming soon)
                    </a>
                  </div>
                </div>
              </div>
              <img
                data-aos="fade-left"
                className="h-100 md:flex hidden self-end"
                alt="sampleape"
                src={
                  "https://d37zfww822zd1w.cloudfront.net/orbitalApesLanding/mint2.png"
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center bg-black px-7 py-24 w-full">
        <div className="flex flex-col max-w-5xl items-center gap-20 text-white w-full">
          <span
            data-aos="fade-up"
            className="text-5xl md:text-7xl text-right font-semibold "
          >
            Perks
          </span>
          <div className="flex w-full flex-wrap justify-around">
            <div
              data-aos="fade-up"
              className="flex gap-1 items-center flex-col"
            >
              <div className="md:w-40 w-32 h-32 md:h-40 bg-transparent">
                <img
                  alt="perk"
                  src="https://d37zfww822zd1w.cloudfront.net/orbitalApesLanding/Piggybank.png"
                />
              </div>
              <span className="text-lg md:text-xl font-medium">
                Orbit Marketplace Fees
              </span>
            </div>

            <div
              data-aos="fade-down"
              data-aos-delay="200"
              className="flex gap-1 items-center flex-col"
            >
              <div className="md:w-40 relative w-32 h-32 md:h-40">
                <img
                  alt="perk"
                  clas
                  src="https://d37zfww822zd1w.cloudfront.net/orbitalApesLanding/CommercialRights.png"
                />
                <div className="absolute w-full h-full top-0 bg-black bg-opacity-0" />
              </div>
              <span className="text-lg md:text-xl font-medium">
                Commercial Rights
              </span>
            </div>

            <div
              data-aos="fade-up"
              data-aos-delay="400"
              className="flex gap-1 items-center flex-col"
            >
              <div className="md:w-40 w-32 h-32 md:h-40 bg-transparent">
                <img
                  alt="perk"
                  src="https://d37zfww822zd1w.cloudfront.net/orbitalApesLanding/Gifts.png"
                />
              </div>
              <span className="text-lg md:text-xl font-medium">
                Validator Rewards
              </span>
            </div>

            <div
              data-aos="fade-down"
              data-aos-delay="600"
              className="flex gap-1 items-center flex-col"
            >
              <div className="md:w-40 w-32 h-32 md:h-40 bg-transparent">
                <img
                  alt="perk"
                  src="https://d37zfww822zd1w.cloudfront.net/orbitalApesLanding/Playtoearn.png"
                />
              </div>
              <span className="text-lg md:text-xl font-medium">
                Play 2 Earn
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          backgroundImage: `url(https://d37zfww822zd1w.cloudfront.net/orbitalApesLanding/1.jpg)`,
        }}
        className="flex  w-full bg-cover  self-center flex-col"
      >
        <div className="gap-2  flex items-center flex-col bg-gradient-to-b from-black to-black via-transparent w-full">
          <div className="gap-2 py-40 text-white flex px-8 justify-center bg-black bg-opacity-50 w-full">
            <div className="gap-9 text-white flex flex-col max-w-7xl items-center justify-center w-full">
              <span
                data-aos="fade-up"
                className="text-5xl md:text-7xl text-center font-semibold "
              >
                Earn Orbit Marketplace Fees
              </span>
              <div data-aos="fade-up" className="flex flex-col">
                <span className="text-xl text-center font-medium ">
                  Orbital Apes holders will be able to claim a portion of all of
                  <span className="text-purple"> Orbit's marketplace fees</span>
                </span>
                <span className="text-xl text-center font-medium ">
                  Orbit NFT marketplace is the first and number one NFT
                  Marketplace in EVMOS
                </span>
              </div>
              <a
                data-aos="fade-up"
                href="https://www.orbitmarket.io/"
                className="border-purple border-2 bg-black bg-opacity-40 shadow font-medium md:text-lg px-5 py-2 rounded-full  text-white"
              >
                Visit Orbit Market
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="flex items-center relative flex-col bg-black gap-44 py-32 w-full">
        <div className="flex flex-col max-w-7xl items-center px-7 gap-20 text-white w-full">
          <span
            data-aos="zoom-in"
            className="text-5xl md:text-7xl text-center md:text-right font-semibold "
          >
            Minting Milestones
          </span>
          <div className="flex w-full flex-wrap gap-5 justify-center">
            <div
              data-aos="fade-up"
              data-aos-delay="250"
              className="flex items-center gap-6 bg-darkGray bg-opacity-30 p-5 rounded-lg flex-col"
            >
              <span className="font-semibold text-2xl">25% Minted</span>
              <img
                src={
                  "https://d37zfww822zd1w.cloudfront.net/orbitalApesLanding/earth.png"
                }
                alt="planet"
                className="w-12"
              />
              <span className="max-w-xsm text-center">
                Orbital Apes Raffle!
                <br />
                Each Oribital Ape holder will have a chance to win 1 of 10
                Orbital Apes!
              </span>
            </div>
            <div
              data-aos="fade-down"
              data-aos-delay="450"
              className="flex items-center gap-6 bg-darkGray bg-opacity-30 p-5 rounded-lg flex-col"
            >
              <span className="font-semibold text-2xl">50% Minted</span>
              <img
                src={
                  "https://d37zfww822zd1w.cloudfront.net/orbitalApesLanding/mars.png"
                }
                alt="planet"
                className="w-12"
              />
              <span className="max-w-xsm text-center">
                Free EXCLUSIVE Orbital Ape ERC-1155 collectible NFT! Airdropped
                to the owners of the first 5,000 Orbital Apes.
              </span>
            </div>
            <div
              data-aos="fade-up"
              data-aos-delay="650"
              className="flex items-center gap-6 bg-darkGray bg-opacity-30 p-5 rounded-lg flex-col"
            >
              <span className="font-semibold text-2xl">75% Minted</span>
              <img
                src={
                  "https://d37zfww822zd1w.cloudfront.net/orbitalApesLanding/jupiter.png"
                }
                alt="planet"
                className="w-12"
              />
              <span className="max-w-xsm text-center">
                Cosmos Ecosystem Giveaway!
                <br />A chance to win from a variety of different Cosmos
                Ecosystem tokens for all holders!
              </span>
              <div className="flex gap-4">
                <img src={Orb} alt="coin" className="w-7 h-7" />
                <img src={Cosmos} alt="coin" className="w-7 h-7" />
                <img src={Terra} alt="coin" className="w-7 h-7" />
                <img src={Secret} alt="coin" className="w-7 h-7" />
              </div>
            </div>
            <div
              data-aos="fade-down"
              data-aos-delay="850"
              className="flex items-center gap-6 bg-darkGray bg-opacity-30 p-5 rounded-lg flex-col"
            >
              <span className="font-semibold text-2xl">100% Minted</span>
              <img
                src={
                  "https://d37zfww822zd1w.cloudfront.net/orbitalApesLanding/solar-system.png"
                }
                alt="planet"
                className="w-12"
              />
              <span className="max-w-xsm text-center">
                Mission complete!
                <br />
                The space journey is just getting started! <br />
                See Below
              </span>
              <img
                alt="arrow"
                className="w-12 animate-bounce"
                src={ArrowDown}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col max-w-7xl items-center px-7 gap-20 text-white w-full">
          <span
            data-aos="fade-up"
            className="text-5xl md:text-7xl text-center text-white md:text-right font-semibold "
          >
            Post Minting Objectives
          </span>
          <div className="flex items-center gap-4 flex-col">
            <span
              data-aos="fade-up"
              className="text-3xl md:text-4xl uppercase text-white text-center md:text-right font-medium"
            >
              ORBITAL APES ODYSSEY
            </span>
            <span
              data-aos="fade-up"
              className="text-white text-center max-w-5xl text-xl"
            >
              An NFT-Based Idle{" "}
              <span className="text-purple">play-to-earn game </span> based on
              the lore of the Orbital Apes, run fully on-chain. Join one of the
              6 Elder factions in their journey to conquer the Cosmos. Breed,
              Terraform, Raid, and become a Mercenary while earning crypto
              rewards and exclusive NFTS!
            </span>
          </div>
          <div className="flex items-center gap-4  flex-col">
            <span
              data-aos="fade-up"
              className="text-3xl md:text-4xl uppercase text-white text-center md:text-right font-medium"
            >
              orbital apes evmos validator
            </span>
            <span
              data-aos="fade-up"
              className="text-white text-center max-w-5xl  text-xl"
            >
              10% of the Minting Proceeds will be self bonded to deploy an{" "}
              <span className="text-purple">EVMOS validator</span> for Orbital
              Apes.
              <br />
              <span className="text-purple">50% of profits</span> generated by
              the validator will be distributed to Orbital Apes Holders.
              <br />
              Hold Orbital Apes and stake with our validator to earn extra
              rewards!
              <br />
              <br />
              By staking with Orbital Apes Vlaidator delegators also earn $OAV
              which can be spent on custom Orbital Apes drops and entries to
              Orbital Apes raffles!
            </span>
          </div>
        </div>
        <img
          alt="roket"
          className="md:w-100 hidden md:flex w-80 bottom-0 md:absolute self-start"
          src={
            "https://d37zfww822zd1w.cloudfront.net/orbitalApesLanding/Rocket.png"
          }
        />
      </div>

      <div
        style={{
          backgroundImage: `url(https://d37zfww822zd1w.cloudfront.net/orbitalApesLanding/SSE_background.png)`,
        }}
        className="flex  w-full bg-cover  self-center flex-col"
      >
        <div className="gap-2  flex items-center flex-col bg-gradient-to-b from-black to-black via-transparent  w-full">
          <div className="gap-2 py-32 text-white flex px-8 flex-col items-center bg-black bg-opacity-30 w-full">
            <div className="gap-16 text-white flex flex-col max-w-6xl items-center justify-center w-full">
              <div className="flex gap-6 items-center justify-center w-full flex-col">
                <span
                  data-aos="fade-up"
                  className="text-5xl md:text-7xl text-center font-semibold "
                >
                  Rarity Ranking {"&"} Distrbution
                </span>
                <div className="flex flex-col">
                  <span
                    data-aos="fade-up"
                    className="text-xl text-center max-w-4xl font-medium "
                  >
                    There is no dress code on this spaceship, every Ape is
                    welcome! Orbital Apes are created from various colors, skins
                    and accessories. Rarity of attributes are used to rank each
                    Orbital Ape on a 5 Tier scale
                  </span>
                  <span
                    data-aos="fade-up"
                    className="text-xl text-center font-medium "
                  >
                    Orbit NFT marketplace is the first and number one NFT
                    Marketplace in EVMOS
                  </span>
                </div>
              </div>
              <div className="flex w-full flex-wrap justify-around">
                <div
                  data-aos="zoom-in"
                  data-aos-delay="250"
                  className="flex gap-2 w-52 items-center flex-col"
                >
                  <div className="w-32 h-32 ">
                    <img
                      alt="perk"
                      src="https://d37zfww822zd1w.cloudfront.net/orbitalApesLanding/Rank1.png"
                    />
                  </div>
                  <span className="text-2xl font-medium">Maintenance Crew</span>
                </div>

                <div
                  data-aos="zoom-in"
                  data-aos-delay="500"
                  className="flex gap-2 w-52 items-center flex-col"
                >
                  <div className="w-32 h-32">
                    <img
                      alt="perk"
                      src="https://d37zfww822zd1w.cloudfront.net/orbitalApesLanding/Rank2.png"
                    />
                  </div>
                  <span className="text-2xl font-medium">Engineers</span>
                </div>

                <div
                  data-aos="zoom-in"
                  data-aos-delay="750"
                  className="flex gap-2 w-52 items-center flex-col"
                >
                  <div className="w-32 h-32">
                    <img
                      alt="perk"
                      src="https://d37zfww822zd1w.cloudfront.net/orbitalApesLanding/Rank3.png"
                    />
                  </div>
                  <span className="text-2xl font-medium">Marines</span>
                </div>

                <div
                  data-aos="zoom-in"
                  data-aos-delay="1000"
                  className="flex gap-2 w-52 items-center flex-col"
                >
                  <div className="w-32 h-32">
                    <img
                      alt="perk"
                      src="https://d37zfww822zd1w.cloudfront.net/orbitalApesLanding/Rank4.png"
                    />
                  </div>
                  <span className="text-2xl font-medium">Pilots</span>
                </div>
                <div
                  data-aos="zoom-in"
                  data-aos-delay="1250"
                  className="flex gap-2 w-52 items-center flex-col"
                >
                  <div className="w-32 h-32">
                    <img
                      alt="perk"
                      src="https://d37zfww822zd1w.cloudfront.net/orbitalApesLanding/Rank5.png"
                    />
                  </div>
                  <span className="text-2xl font-medium">Commanders</span>
                </div>
              </div>
            </div>
            <div className="flex mt-32 items-center relative flex-col gap-14 w-full">
              <span
                data-aos="fade-up"
                className="text-3xl text-white md:text-5xl text-center font-semibold "
              >
                Ape Generations
              </span>
              <div data-aos="fade-up" className="flex max-w-4xl flex-1  w-full">
                <img
                  alt="generations"
                  src="https://d37zfww822zd1w.cloudfront.net/orbitalApesLanding/GenerationBar-18 (1).png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center relative flex-col bg-black gap-32 py-48 pb-24 w-full">
        <div className="flex flex-col max-w-7xl items-center px-7 gap-20 text-white w-full">
          <span
            data-aos="fade-up"
            className="text-5xl md:text-7xl text-right font-semibold "
          >
            Our Partners
          </span>
        </div>
        <div className="flex flex-wrap justify-center gap-10 items-center w-full">
          <a data-aos="fade-up" href="https://twitter.com/CosmosUplink">
            <img
              className="h-24"
              alt="partner"
              src={
                "https://d37zfww822zd1w.cloudfront.net/orbitalApesLanding/partners/Uplink.png"
              }
            />
          </a>
          <a data-aos="fade-up" href="https://diffusion.fi/">
            <img
              className="h-12"
              alt="partner"
              src={
                "https://d37zfww822zd1w.cloudfront.net/orbitalApesLanding/partners/Diffusion.png"
              }
            />
          </a>
          <a data-aos="fade-up" href="https://www.kinesislabs.co/">
            <img
              className="h-28"
              alt="partner"
              src={
                "https://d37zfww822zd1w.cloudfront.net/orbitalApesLanding/partners/Kinesis.png"
              }
            />
          </a>
          <a data-aos="fade-up" href="https://coslend.com/">
            <img
              className="h-24"
              alt="partner"
              src={
                "https://d37zfww822zd1w.cloudfront.net/orbitalApesLanding/partners/Coslend.png"
              }
            />
          </a>
          <a data-aos="fade-up" href="https://evmos.domains/">
            <img
              className="h-24"
              alt="partner"
              src={
                "https://d37zfww822zd1w.cloudfront.net/orbitalApesLanding/partners/evmosDomains.svg"
              }
            />
          </a>
          <a data-aos="fade-up" href="https://twitter.com/nomadxyz_">
            <img
              className="h-18"
              alt="partner"
              src={
                "https://d37zfww822zd1w.cloudfront.net/orbitalApesLanding/partners/Logo-White.png"
              }
            />
          </a>
        </div>
      </div>

      <div className="flex items-center relative flex-col bg-black gap-44 py-48 w-full">
        <div className="flex flex-col max-w-7xl items-center px-7 gap-20 text-white w-full">
          <span
            data-aos="fade-up"
            className="text-5xl md:text-7xl text-right font-semibold "
          >
            Meet The Team
          </span>
          <div className="flex justify-center max-w-3xl flex-wrap gap-5">
            <div
              data-aos="fade-left"
              data-aos-delay="150"
              className="flex w-40 md:w-44 items-center flex-col gap-6"
            >
              <div className="flex w-40 h-40 flex-col bg-gradient-to-b p-1.5 from-black to-purple rounded-full">
                <img
                  alt="team"
                  className="flex-1 rounded-full"
                  src={
                    "https://d37zfww822zd1w.cloudfront.net/orbitalApesLanding/team9.jpg"
                  }
                />
              </div>
              <div className="flex items-center flex-col">
                <a
                  className="text-white uppercase text-lg md:text-xl"
                  href="https://twitter.com/0xCrtlAltApe"
                >
                  0xCtrlAltApe{" "}
                </a>
                <span className="text-purple">Señor Solidity</span>
              </div>
            </div>
            <div
              data-aos="fade-right"
              data-aos-delay="300"
              className="flex w-40 md:w-44 items-center flex-col gap-6"
            >
              <div className="flex w-40 h-40  flex-col bg-gradient-to-b p-1.5 from-black to-purple rounded-full">
                <img
                  alt="team"
                  className="flex-1 rounded-full"
                  src={
                    "https://d37zfww822zd1w.cloudfront.net/orbitalApesLanding/team3.jpg"
                  }
                />
              </div>
              <div className="flex items-center flex-col">
                <span className="text-white uppercase text-lg md:text-xl">
                  Barlumin
                </span>
                <span className="text-purple">Spacerace Coordinator</span>
              </div>
            </div>

            <div
              data-aos="fade-left"
              data-aos-delay="450"
              className="flex w-40 md:w-44 items-center flex-col gap-6"
            >
              <div className="flex w-40 h-40  flex-col bg-gradient-to-b p-1.5 from-black to-purple rounded-full">
                <img
                  alt="team"
                  className="flex-1 rounded-full"
                  src={
                    "https://d37zfww822zd1w.cloudfront.net/orbitalApesLanding/team4.jpg"
                  }
                />
              </div>
              <div className="flex items-center flex-col">
                <span className="text-white uppercase text-lg md:text-xl">
                  Mr. Sir
                </span>
                <span className="text-purple">Full-stack Wizard</span>
              </div>
            </div>

            <div
              data-aos="fade-right"
              data-aos-delay="600"
              className="flex w-40 md:w-44 items-center flex-col gap-6"
            >
              <div className="flex w-40 h-40  flex-col bg-gradient-to-b p-1.5 from-black to-purple rounded-full">
                <img
                  alt="team"
                  className="flex-1 rounded-full"
                  src={
                    "https://d37zfww822zd1w.cloudfront.net/orbitalApesLanding/team7.jpg"
                  }
                />
              </div>
              <div className="flex items-center flex-col">
                <span className="text-white uppercase text-lg md:text-xl">
                  Ariandances
                </span>
                <span className="text-purple">Community Surfer</span>
              </div>
            </div>

            <div
              data-aos="fade-left"
              data-aos-delay="750"
              className="flex w-40 md:w-44 items-center flex-col gap-6"
            >
              <div className="flex w-40 h-40  flex-col bg-gradient-to-b p-1.5 from-black to-purple rounded-full">
                <img
                  alt="team"
                  className="flex-1 rounded-full"
                  src={
                    "https://d37zfww822zd1w.cloudfront.net/orbitalApesLanding/team2.jpg"
                  }
                />
              </div>
              <div className="flex items-center flex-col">
                <span className="text-white uppercase text-lg md:text-xl">
                  Co
                </span>
                <span className="text-purple">Community Overlord</span>
              </div>
            </div>

            <div
              data-aos="fade-right"
              data-aos-delay="900"
              className="flex w-40 md:w-44 items-center flex-col gap-6"
            >
              <div className="flex w-40 h-40 flex-col bg-gradient-to-b p-1.5 from-black to-purple rounded-full">
                <img
                  alt="team"
                  className="flex-1 rounded-full"
                  src={
                    "https://d37zfww822zd1w.cloudfront.net/orbitalApesLanding/team10.jpg"
                  }
                />
              </div>
              <div className="flex items-center flex-col">
                <span className="text-white uppercase text-lg md:text-xl">
                  Baz
                </span>
                <span className="text-purple">Master of Apes</span>
              </div>
            </div>
            <div
              data-aos="fade-right"
              data-aos-delay="900"
              className="flex w-40 md:w-44 items-center flex-col gap-6"
            >
              <div className="flex w-40 h-40 flex-col bg-gradient-to-b p-1.5 from-black to-purple rounded-full">
                <img
                  alt="team"
                  className="flex-1 rounded-full"
                  src={
                    "https://d37zfww822zd1w.cloudfront.net/orbitalApesLanding/team8.jpg"
                  }
                />
              </div>
              <div className="flex items-center flex-col">
                <span className="text-white uppercase text-lg md:text-xl">
                  Sebb
                </span>
                <span className="text-purple">Ape Conossir</span>
              </div>
            </div>
            <div
              data-aos="fade-right"
              data-aos-delay="900"
              className="flex w-40 md:w-44 items-center flex-col gap-6"
            >
              <div className="flex w-40 h-40 flex-col bg-gradient-to-b p-1.5 from-black to-purple rounded-full">
                <img
                  alt="team"
                  className="flex-1 rounded-full"
                  src={
                    "https://d37zfww822zd1w.cloudfront.net/orbitalApesLanding/team6.jpg"
                  }
                />
              </div>
              <div className="flex items-center flex-col">
                <span className="text-white uppercase text-lg md:text-xl">
                  ShuttleJApe
                </span>
                <span className="text-purple">Cabin Crew</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          backgroundImage: `url(https://d37zfww822zd1w.cloudfront.net/orbitalApesLanding/background1.png)`,
        }}
        className="flex  w-full bg-cover  self-center flex-col"
      >
        <div className="gap-2  flex items-center flex-col bg-gradient-to-b from-black to-black via-transparent w-full">
          <div className="py-32 text-white flex px-8 flex-col gap-10 items-center bg-black bg-opacity-80 w-full">
            <span
              data-aos="fade-up"
              className="text-5xl md:text-7xl text-right font-semibold "
            >
              FAQ
            </span>
            <FAQ />
          </div>
        </div>
      </div>
      <div className="flex items-center relative flex-col bg-black gap-44 py-10 w-full">
        <div className="flex flex-col max-w-7xl items-center px-7 gap-20 text-white w-full">
          <div className="flex justify-between w-full max-w-7xl">
            <div className="flex flex-col gap-2 w-full max-w-7xl">
              <div className="flex text-white items-center gap-1">
                <span>Copyright 2022 Orbital Apes</span>
              </div>
              {/* <div className="flex text-lg items-center gap-3">
                <span className="font-bold font-lg text-white">About</span>
                <span className="font-bold font-lg text-white">Contact</span>
                <span className="font-bold font-lg text-white">Help</span>
                <span className="font-bold font-lg text-white">
                  Terms of Service
                </span>
              </div> */}
            </div>
            <div className="flex self-end gap-3">
              <a
                href="https://discord.gg/orbitalapes "
                className=" w-8 h-8 rounded-full"
              >
                <img className="w-full" alt="discord" src={Discord} />
              </a>
              <a
                href="https://twitter.com/OrbitalApes"
                className=" w-7 h-7 rounded-full"
              >
                <img className="w-full" alt="twitter" src={Twitter} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;

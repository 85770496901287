import {
  generateEndpointDistributionRewardsByAddress,
  generateEndpointGetDelegations,
  generateEndpointGetUndelegations,
  generateEndpointGetValidators,
} from "@tharsis/provider";

import {
  generateEndpointBalances,
  generateEndpointAccount,
} from "@tharsis/provider";
import { NODE_URL } from "../network";

import { ethToEvmos } from "@tharsis/address-converter";
import { NO_PUBKEY } from "./constants";

export async function getBlockchainInfo(wallet, setStatus) {
  let [balances, delegations, rewards, undelegations, validators] =
    await Promise.all([
      getAllBalances(wallet),
      getDelegations(wallet),
      getDistributionRewards(wallet),
      getUndelegations(wallet),
      getValidators(),
    ]);

  setStatus({
    balances: balances.balances,
    delegations: delegations,
    rewards,
    undelegations,
    validators,
  });
}

// function getStakes(){

// }

export async function getValidators() {
  try {
    const get = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    const resp = await fetch(
      `${NODE_URL}${generateEndpointGetValidators()}?status=BOND_STATUS_BONDED&pagination.limit=200`,
      get
    );
    const validators = await resp.json();
    if (validators.code) {
      // Node error
      return [];
    }
    const parsed = validators.validators;
    return parsed;
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function getDistributionRewards(address) {
  try {
    const get = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    const resp = await fetch(
      `${NODE_URL}${generateEndpointDistributionRewardsByAddress(address)}`,
      get
    );
    const rewards = await resp.json();
    if (rewards.code) {
      // Node error
      return [];
    }
    const parsed = rewards.rewards;
    return parsed;
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function getDelegations(address) {
  const get = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  try {
    const resp = await fetch(
      `${NODE_URL}${generateEndpointGetDelegations(address)}`,
      get
    );
    const value = await resp.json();
    if (value.code) {
      return [];
    }
    const parsed = value;
    return parsed.delegation_responses;
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function getUndelegations(address) {
  const get = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  try {
    const resp = await fetch(
      `${NODE_URL}${generateEndpointGetUndelegations(address)}`,
      get
    );
    const value = await resp.json();
    if (value.code) {
      return [];
    }
    const parsed = value;
    return parsed.unbonding_responses;
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function getAllBalances(address) {
  if (address === null) {
    return { balances: [] };
  }

  try {
    const balance = await fetch(
      `${NODE_URL}${generateEndpointBalances(address)}`
    );
    const parsed = await balance.json();
    return parsed;
  } catch (e) {
    console.error(e);
    return { balances: [] };
  }
}

export async function getAccount(wallet) {
  const get = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  let resp;
  try {
    const addr = await fetch(
      `${NODE_URL}${generateEndpointAccount(wallet)}`,
      get
    );
    resp = await addr.json();
    if (resp.code === 5) {
      // If error 400 wallet doesn't exists
      console.error(resp);
      return null;
    }
  } catch (e) {
    // RPC endpoint is dead!
    console.error(e);
    return null;
  }
  return {
    accountAddress: wallet,
    sequence: parseInt(resp.account.base_account.sequence, 10),
    accountNumber: parseInt(resp.account.base_account.account_number, 10),
  };
}

export async function queryPubKey(address) {
  let converted = address;
  if (converted.split("0x").length === 2) {
    converted = ethToEvmos(converted);
  }

  const get = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  let resp;
  try {
    const addr = await fetch(
      `${NODE_URL}${generateEndpointAccount(converted)}`,
      get
    );
    // If error 400 wallet doesn't exists
    resp = await addr.json();
  } catch (e) {
    console.error(e);
    return NO_PUBKEY;
  }
  if ("account" in resp) {
    if ("base_account" in resp.account) {
      if ("pub_key" in resp.account.base_account) {
        if (resp.account.base_account.pub_key !== null) {
          return resp.account.base_account.pub_key.key;
        }
      }
    }
  }

  return NO_PUBKEY;
}

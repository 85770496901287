import DesktopHeader from "./DesktopHeader";

function Header() {
  return (
    <>
      <DesktopHeader />
    </>
  );
}

export default Header;

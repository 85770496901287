import { EVMOS_DENOMINATION, FEE, VALIDATOR } from "../../utils/network";
import { prepareAccount } from "./utils";
import { createTxMsgUndelegate } from "@tharsis/transactions";
import { signCosmosAndBroadcast } from "../../utils/wallets/signer";
import { useState } from "react";
import { ethers } from "ethers";

async function executeUndelegate(provider, wallet, pubkey, amount) {
  const values = await prepareAccount(provider, wallet, pubkey);
  if (values === null) {
    return;
  }

  const params = {
    validatorAddress: VALIDATOR,
    amount: ethers?.utils?.parseEther(amount).toString(),
    denom: EVMOS_DENOMINATION,
  };


  const msg = createTxMsgUndelegate(
    values.chain,
    values.sender,
    FEE,
    "",
    params
  );

  const res = await signCosmosAndBroadcast(
    provider,
    values.chain,
    values.sender,
    msg
  );
  return res;
}

const Undelegate = ({ provider, wallet, pubkey, staked }) => {
  const [amount, setAmount] = useState(0);
  return (
    <div className="flex self-start gap-4 px-3 w-full flex-col">
      <div className="flex flex-1 flex-col">
        <h4 className="font-medium">From:</h4>
        <div className="border-gray py-0.5 flex-1 bg-dark rounded px-2">
          Orbital Apes - evmosvaloper1mx9nq...tmxq7zjqep5khu
        </div>
      </div>

      <div className="flex flex-1 flex-col">
        <h4 className="font-medium">Amount:</h4>
        <div className="flex flex-1 flex-col justify-between gap-2">
          <input
            className="border-gray flex-1 bg-dark rounded px-2"
            type="text"
            placeholder="0"
            value={amount}
            onChange={(e) => {
              setAmount(e.target.value);
            }}
          />
          <span className="text-sm mt-1">
            Available to unstake: {staked || 0} EVMOS
          </span>
        </div>
      </div>
      <button
        className="border  border-dark bg-dark rounded w-32  self-center"
        onClick={async () => {
          await executeUndelegate(provider, wallet, pubkey, amount);
        }}
      >
        Undelegate
      </button>
    </div>
  );
};

export default Undelegate;

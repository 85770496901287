import WalletConnect from "../../components/wallet/walletConnect";
import { useLocalStorage } from "../../utils/wallets/useLocalStorage";
import {
  KEPLR_PROVIDER,
  METAMASK_PROVIDER,
  NO_PROVIDER,
  NO_PUBKEY,
  NO_WALLET,
} from "../../utils/wallets/constants";
import { useEffect, useState } from "react";
import { connectMetamask } from "../../utils/wallets/metamask";
import { connectKeplr } from "../../utils/wallets/keplr";
import { getBlockchainInfo } from "../../utils/wallets/status";
import {
  convertAmountForFrontend,
  EVMOS_DENOMINATION,
  VALIDATOR,
} from "../../utils/network";
// import ClaimRewards from "../../components/staking/claimRewards";
import Delegate from "../../components/staking/delegate";
import Undelegate from "../../components/staking/undelegate";
import "../../polyfill";
import cs from "classnames";
import DiscordDelegate from "../../components/Delegate";
import Restake from "../../components/restake/restake";
// import { evmosToEth } from "@tharsis/address-converter";

export default function Validator() {
  const [provider, setProvider] = useLocalStorage("provider", NO_PROVIDER);
  const [wallet, setWallet] = useLocalStorage("wallet", NO_WALLET);
  const [pubkey, setPubkey] = useLocalStorage("pubkey", NO_PUBKEY);
  const [status, setStatus] = useLocalStorage("status", { balances: [] });
  const [menu, setMenu] = useState(0);

  const pendingUndelegations = convertAmountForFrontend(
    status.undelegations
      ?.find((e) => e.validator_address === VALIDATOR)?.entries?.reduce((p, a) => {
        return p + parseFloat(a.balance);
      }, 0)
  );

  const rewards = convertAmountForFrontend(
    status.rewards?.find((e) => e.validator_address === VALIDATOR)?.reward[0]
      ?.amount
  );

  const stakedBalance = convertAmountForFrontend(
    status.delegations?.find(
      (e) => e.delegation.validator_address === VALIDATOR
    )?.balance?.amount
  );

  const balance = convertAmountForFrontend(
    status.balances?.find((e) => e.denom === EVMOS_DENOMINATION)?.amount
  );

  const otherDelegations = status?.delegations?.filter(
    (e) => e.delegation.validator_address !== VALIDATOR
  );

  // Reconnect to the same provider if the users reenters the app
  // Edit this effect on your app to avoid calling the connectWallet twice
  useEffect(() => {
    if (provider === METAMASK_PROVIDER) {
      connectMetamask(setProvider, setWallet, setPubkey, true);
      return;
    }
    if (provider === KEPLR_PROVIDER) {
      connectKeplr(setProvider, setWallet, setPubkey);
      return;
    }
  });

  useEffect(() => {
    if (wallet !== NO_WALLET) {
      getBlockchainInfo(wallet, setStatus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet]);

  return (
    <div className="text-white bg-dark py-12 pt-8 flex flex-col gap-6 items-center">
      {provider && wallet !== NO_WALLET && (
        <WalletConnect
          setProvider={setProvider}
          setWallet={setWallet}
          setPubkey={setPubkey}
          wallet={wallet}
        />
      )}

      <span
        data-aos="fade-up"
        className="text-5xl md:text-7xl text-center md:text-right font-semibold "
      >
        Orbital Apes Validator Station
      </span>

      {wallet === NO_WALLET && (
        <div className="flex items-center gap-4 mt-16 flex-col">
          <span className="text-2xl font-semibold">
            Connect your wallet to continue
          </span>
          <WalletConnect
            setProvider={setProvider}
            setWallet={setWallet}
            setPubkey={setPubkey}
          />
        </div>
      )}
      {/* <h3>Debug Info:</h3>
      <p>Provider: {provider}</p>
      <p>Cosmos Wallet: {wallet}</p>
      <p>Eth Wallet: {wallet !== NO_WALLET ? evmosToEth(wallet) : ""}</p>
      <p>
        Balance:{" "}
        {`${convertAmountForFrontend(
          status.balances?.find((e) => e.denom === EVMOS_DENOMINATION)?.amount
        )} EVMOS`}
      </p>
      <p>Validator: {VALIDATOR}</p> */}

      {provider && wallet !== NO_WALLET && (
        <>
          <div className="flex mt-8 gap-4">
            <div className="flex w-60 flex-col items-center rounded bg-darkGray p-3">
              <span className="text-xl font-semibold">Your Balance</span>
              <span>{`${balance} EVMOS`}</span>
            </div>
            <div className="flex w-60 flex-col items-center rounded bg-darkGray p-3">
              <span className="text-xl font-semibold">Staked Balance</span>
              <span>
                {stakedBalance}
                {" EVMOS"}
              </span>
            </div>
            <div className="flex w-60 flex-col items-center rounded bg-darkGray p-3">
              <span className="text-xl font-semibold">Your Rewards</span>
              <span>
                {rewards}
                {" EVMOS"}
              </span>
            </div>
            <div className="flex w-60 flex-col items-center rounded bg-darkGray p-3">
              <span className="text-xl font-semibold">
                Pending Undelegations
              </span>
              <span>
                {pendingUndelegations}
                {" EVMOS"}
              </span>
            </div>
          </div>

          {otherDelegations?.length > 0 && (
            <Restake
              validators={status.validators}
              otherDelegations={otherDelegations}
              provider={provider}
              wallet={wallet}
              pubkey={pubkey}
            />
          )}

          <div className="flex border border-purple rounded h-8">
            <button
              onClick={() => setMenu(0)}
              className={cs("px-2 w-24 ", {
                "bg-purple": menu === 0,
              })}
            >
              Stake
            </button>
            <button
              onClick={() => setMenu(1)}
              className={cs("px-2 w-24 border-r border-purple border-l", {
                "bg-purple": menu === 1,
              })}
            >
              Unstake
            </button>
            {stakedBalance > 0 && (
              <button
                onClick={() => setMenu(2)}
                className={cs("px-2 w-24", {
                  "bg-purple": menu === 2,
                })}
              >
                Perks
              </button>
            )}
          </div>

          {menu === 0 && (
            <div className=" bg-darkGray gap-3 max-w-lg items-center rounded flex flex-col p-3">
              <span className="font-semibold text-3xl ">Stake 🚀</span>
              <span className="text-center">
                Delegate to Orbital Apes and earn $OAV (Orbital Apes Validator)
                token on top of your $EVMOS APY
              </span>
              <Delegate
                provider={provider}
                wallet={wallet}
                pubkey={pubkey}
                balance={balance}
              />
            </div>
          )}
          {menu === 1 && (
            <div className=" bg-darkGray gap-3 max-w-lg items-center rounded flex flex-col p-3">
              <span className="font-semibold text-3xl ">Unstake 🙈</span>
              <span className="text-center">
                Delegate to Orbital Apes and earn $OAV (Orbital Apes Validator)
                token on top of yout $EVMOS APY
              </span>
              <Undelegate
                provider={provider}
                wallet={wallet}
                pubkey={pubkey}
                staked={stakedBalance}
              />
            </div>
          )}

          {menu === 2 && stakedBalance > 0 && (
            <div className="flex max-w-xl w-full gap-9 bg-darkGray p-3 rounded flex-col">
              <span className="text-3xl text-center font-semibold">
                Get Validator Discord Role
              </span>
              <DiscordDelegate />
            </div>
          )}
        </>
      )}
    </div>
  );
}

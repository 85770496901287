
import { useState } from "react";

import RestakeRow from "./RestakeRow";
import RightArrow from "../../assets/icons/arrow-right-svgrepo-com.svg";
import cs from "classnames";



export default function Restake({ otherDelegations, validators, provider, wallet, pubkey }) {
  const [open, setOpen] = useState(true);

  

  return (
    <div className="flex flex-col border border-purple rounded p-4 bg-purple bg-opacity-20 w-full max-w-3xl mt-4 gap-4">
      <div onClick={() => setOpen(!open)} className="flex cursor-pointer justify-between">
      <span>
        Other delegations
      </span>
      <img
        alt="arrow"
        className={cs("transform origin-center", {
          "rotate-90": !open,
          "-rotate-90": open,
        })}
        src={RightArrow}
      />
      </div>
      {open && (
        <div className="flex flex-col">
          {otherDelegations.map((d, index) => {
            let validatorData = validators?.find(
              (v) => v.operator_address === d.delegation.validator_address
            );

            return (
              <RestakeRow 
              provider={provider}
              wallet={wallet}
              pubkey={pubkey}
              key={index} data={d} validatorData={validatorData} />
            );
          })}
        </div>
      )}
    </div>
  );
}

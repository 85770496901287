import { createTxRaw } from "@tharsis/proto";
import { COSMOS_CHAIN_ID } from "../network";
import { KEPLR_PROVIDER, NO_PROVIDER, NO_PUBKEY, NO_WALLET } from "./constants";

const LEDGER_ERROR_MESSAGE =
  "Ledger is not compatible with this coinType right now";

async function doConnection(setProvider, setWallet, setPubkey) {
  if (!window.getOfflineSignerOnlyAmino || !window.keplr) {
    alert("Error with Keplr: Please install keplr extension");
    return;
  }
  try {
    await window.keplr.enable(COSMOS_CHAIN_ID);
    const offlineSigner = window.getOfflineSigner(COSMOS_CHAIN_ID);
    const wallets = await offlineSigner.getAccounts();
    setProvider(KEPLR_PROVIDER);
    setWallet(wallets[0].address);
    setPubkey(Buffer.from(wallets[0].pubkey).toString("base64"));
  } catch (e) {
    // There's currently not support for hardware wallets in keplr
    if (e.message === LEDGER_ERROR_MESSAGE) {
      setProvider(NO_PROVIDER);
      setWallet(NO_WALLET);
      setPubkey(NO_PUBKEY);
    }
  }
}

export async function connectKeplr(setProvider, setWallet, setPubkey) {
  await doConnection(setProvider, setWallet, setPubkey);
  window.addEventListener("keplr_keystorechange", () => {
    connectKeplr(setProvider, setWallet, setPubkey);
  });
}

export async function signWithKeplrCosmosTx(chain, sender, tx) {
  // TODO: display error if not enabled
  await window.keplr.enable(chain.cosmosChainId);

  const sign = await window.keplr.signDirect(
    chain.cosmosChainId,
    sender.accountAddress,
    {
      bodyBytes: tx.signDirect.body.serializeBinary(),
      authInfoBytes: tx.signDirect.authInfo.serializeBinary(),
      chainId: chain.cosmosChainId,
      accountNumber: sender.accountNumber,
    },
    { isEthereum: true }
  );

  return createTxRaw(sign.signed.bodyBytes, sign.signed.authInfoBytes, [
    new Uint8Array(Buffer.from(sign.signature.signature, "base64")),
  ]);
}

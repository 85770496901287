import { EVMOS_DENOMINATION, FEE, VALIDATOR } from "../../utils/network";
import { signCosmosAndBroadcast } from "../../utils/wallets/signer";
import { prepareAccount } from "./utils";
import { createTxMsgDelegate } from "@tharsis/transactions";
import { useState } from "react";
import { ethers } from "ethers";

async function executeDelegate(provider, wallet, pubkey, amount) {
  const values = await prepareAccount(provider, wallet, pubkey);
  if (values === null) {
    return;
  }

  const params = {
    validatorAddress: VALIDATOR,
    amount: ethers.utils.parseEther(amount).toString(),
    denom: EVMOS_DENOMINATION,
  };

  const msg = createTxMsgDelegate(values.chain, values.sender, FEE, "", params);

  const res = await signCosmosAndBroadcast(
    provider,
    values.chain,
    values.sender,
    msg
  );
  return res;
}

const Delegate = ({ provider, wallet, pubkey, balance }) => {
  // 0.1 EVMOS reserved for fee (this can be changed)
  const RESERVED_FOR_FEE = 0.1;
  const [amount, setAmount] = useState(0);
  return (
    <div className="flex self-start gap-4 px-3 w-full flex-col">
      <div className="flex flex-1 flex-col">
        <h4 className="font-medium">To:</h4>
        <div className="border-gray py-0.5 flex-1 bg-dark rounded px-2">
          Orbital Apes - evmosvaloper1mx9nq...tmxq7zjqep5khu
        </div>
      </div>
      { balance >= 0.1 &&
      <div className="flex flex-1 flex-col">
      <h4 className="font-medium">Amount:</h4>
      <div className="flex flex-1 justify-between gap-2">
        <input
          className="border-gray flex-1 bg-dark rounded px-2"
          type="text"
          placeholder="0"
          value={amount}
          onChange={(e) => {
            setAmount(e.target.value);
          }}
        />
        <button
          className="bg-dark px-4 rounded"
          onClick={() => {
            if (balance) {
               setAmount(
                (balance - RESERVED_FOR_FEE)
              );
            }
          }}
        >
          MAX
        </button>
      </div>
      <span className="text-sm mt-1">
        Available to stake: {balance || 0} $EVMOS
      </span>
      <span className="text-sm text-center mt-3">
      0.1 EVMOS is reserved for transaction fees.
      </span>
      
    </div>
      }

{ balance < 0.1 &&
<div className="flex flex-col">
  <span className="text-center">You need more than 0.1 EVMOS to delegate</span>
  <span className="text-sm text-center mt-1">
   (0.1 EVMOS is reserved for transaction fees.)
  </span>
  </div>
}

{ balance >= 0.1 &&
  <button
  className="border  border-dark bg-dark rounded w-32  self-center"
  onClick={async () => {
    await executeDelegate(provider, wallet, pubkey, amount);
  }}
>
  Delegate
</button>
}

      
    </div>
  );
};

export default Delegate;

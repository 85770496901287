import { KEPLR_PROVIDER, METAMASK_PROVIDER } from "./constants";

import {
  BroadcastMode,
  generateEndpointBroadcast,
  generatePostBodyBroadcast,
} from "@tharsis/provider";
import { EVMOS_JRPC, NODE_URL } from "../network";
import { signWithMetamaskEip712 } from "./metamask";
import { signWithKeplrCosmosTx } from "./keplr";
import {
  dismissToast,
  errorToast,
  loadingToast,
  successToast,
} from "../../components/Toasts";

export async function broadcastRawTransaction(rawTx) {
  // Broadcast it
  const postOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: generatePostBodyBroadcast(rawTx, BroadcastMode.Sync),
  };

  try {
    alert("The transaction is being broadcasted");

    const broadcastPost = await fetch(
      `${NODE_URL}${generateEndpointBroadcast()}`,
      postOptions
    );
    const response = await broadcastPost.json();

    if (response.tx_response.code !== 0) {
      alert(`Transaction Failed  ${response.tx_response.raw_log}`);
      return null;
    }

    alert(`Transaction sent with hash: ${response.tx_response.txhash}`);
    return response.tx_response;
  } catch (e) {
    console.error(e);
    alert("Transaction Failed: Unknown error!");
    return null;
  }
}

async function checkTx(txHash) {
  try {
    const rawRes = await fetch(`${EVMOS_JRPC}/tx?hash=0x${txHash}`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    let res = await rawRes.json();

    // Transaction not found (not included in a block)
    if (res.error && res.error.code === -32603) {
      return -1;
    }

    // Success
    if (res.result.tx_result.code === 0) {
      return 0;
    }
  } catch (e) {
    console.error(e);
  }

  // HTTP error or not code 0, is that the transaction have failed
  return 1;
}

function sleep(milliseconds) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

async function checkTxInclusionInABlock(txHash) {
  while (true) {
    let status = await checkTx(txHash);
    if (status === -1) {
      await sleep(3000);
    } else {
      return status;
    }
  }
}

export async function signCosmosAndBroadcast(provider, chain, sender, tx) {
  let rawTx = null;
  if (provider === METAMASK_PROVIDER) {
    rawTx = await signWithMetamaskEip712(chain, sender, tx);
  }
  if (provider === KEPLR_PROVIDER) {
    rawTx = await signWithKeplrCosmosTx(chain, sender, tx);
  }

  if (rawTx !== null) {
    const toastId = loadingToast("Processing your request");
    const res = await broadcastRawTransaction(rawTx);
    // return res;
    if (res !== null) {
      const included = await checkTxInclusionInABlock(res.txhash);
      if (included === 0) {
        dismissToast(toastId);
        successToast("Transaction Included in a block!");
      } else {
        dismissToast(toastId);
        errorToast("ERROR: Transaction not included in a block!");
      }
    }
  }
}

import { evmosToEth } from "@tharsis/address-converter";
import { signatureToPubkey } from "@hanchon/signature-to-pubkey";
import {
  METAMASK_PROVIDER,
  NO_PROVIDER,
  NO_PUBKEY,
  NO_WALLET,
} from "./constants";
import { ethToEvmos } from "@tharsis/address-converter";
import {
  createTxRawEIP712,
  signatureToWeb3Extension,
} from "@tharsis/transactions";
import { queryPubKey } from "./status";

let started = false;


async function handleAccountsChanged(
  accounts,
  setProvider,
  setWallet,
  setPubkey,
  skipSign
) {
  if (started) return;
  started = true;
  if (accounts.length === 0) {
    // set everything as null and refresh
    setProvider(NO_PROVIDER);
    setWallet(NO_WALLET);
    setPubkey(NO_PUBKEY);
    return;
  }

  setProvider(METAMASK_PROVIDER);
  setWallet(ethToEvmos(accounts[0]));
  // Try to get the pubkey from the chain
  let pubkey = await queryPubKey(accounts[0]);
  if (pubkey === NO_PUBKEY && !skipSign) {
    // Make sure to avoid for this call before recalling the handleAccountChanged to avoid making the user sign the message several times
    pubkey = await generatePubkeyFromSignature(accounts[0]);
  }
  setPubkey(pubkey);
  started = false;
  return;
}

export async function connectMetamask(setProvider, setWallet, setPubkey, skipSign) {
  try {
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    await handleAccountsChanged(accounts, setProvider, setWallet, setPubkey, skipSign);
  } catch (e) {
    console.error(`could not connect${e}`);
  }
}

export async function generatePubkeyFromSignature(ethWallet) {
  const signature = await window.ethereum.request({
    method: "personal_sign",
    params: [ethWallet, "generate_pubkey"],
  });
  const message = Buffer.from([
    50, 215, 18, 245, 169, 63, 252, 16, 225, 169, 71, 95, 254, 165, 146, 216,
    40, 162, 115, 78, 147, 125, 80, 182, 25, 69, 136, 250, 65, 200, 94, 178,
  ]);

  return signatureToPubkey(signature, message);
}

export async function createEIP712Transaction(chain, sender, signature, tx) {

  // The chain and sender objects are the same as the previous example
  const extension = signatureToWeb3Extension(chain, sender, signature);

  // Create the txRaw
  return createTxRawEIP712(
    tx.legacyAmino.body,
    tx.legacyAmino.authInfo,
    extension
  );
}

export async function signWithMetamaskEip712(chain, sender, tx) {
  let signature = "";
  try {
    signature = await window.ethereum.request({
      method: "eth_signTypedData_v4",
      params: [evmosToEth(sender.accountAddress), JSON.stringify(tx.eipToSign)],
    });
  } catch (e) {
    alert("Metamask error!");
    console.error(e);
    return null;
  }

  const rawTx = await createEIP712Transaction(chain, sender, signature, tx);
  return rawTx;
}

import { COSMOS_CHAIN_ID, ETH_CHAIN_ID } from "../../utils/network";
import { NO_PROVIDER, NO_WALLET } from "../../utils/wallets/constants";
import { generatePubkeyFromSignature } from "../../utils/wallets/metamask";
import { getAccount } from "../../utils/wallets/status";

export async function prepareAccount(provider, wallet, pubkey) {
  if (wallet === NO_WALLET || provider === NO_PROVIDER) {
    alert("No wallet!");
    return null;
  }

  let account = await getAccount(wallet);
  if (account === null) {
    alert("Error getting account info!");
    return null;
  }
  if (!pubkey) {
    pubkey = await generatePubkeyFromSignature(wallet);
  }
  account["pubkey"] = pubkey;

  return {
    chain: { chainId: ETH_CHAIN_ID, cosmosChainId: COSMOS_CHAIN_ID },
    sender: account,
  };
}

import { useEffect, useState } from "react";
import Web3 from "web3";
import { fetchData } from "../lambda/lambda";
import Example from "../assets/placeholders/discordUser.png";
import { dismissToast, errorToast, loadingToast, successToast } from "./Toasts";

const web3 = new Web3(window.ethereum);
window?.ethereum?.request({ method: "eth_requestAccounts" });

const DELEGATOR_ROLE_URL = "https://api.orbitalapes.com/getDelegatorRole?";
const ADDRESS_LINKED_URL = "https://api.orbitalapes.com/isAddressLinked?";

export default function Delegate() {
  const [userName, setUserName] = useState("");
  const [linkedData, setLinkedData] = useState({});

  async function handleGetRole() {
    const addresses = await web3.eth.getAccounts();
    const currentAddress = addresses[0];
    const dataToSign = `data ${userName}`;
    const signature = await web3.eth.personal.sign(
      web3.utils.toHex(dataToSign),
      currentAddress,
      (err, res) => console.log(err, res)
    );
    const toastId = loadingToast("Processing your request");
    const query = `username=${encodeURIComponent(userName)}&signature=${signature}&publicAddress=${encodeURIComponent(
      currentAddress
    )}`;
    try {
      await fetchData(DELEGATOR_ROLE_URL + query);
      dismissToast(toastId);
      successToast("Added delegator role to you user!");
    } catch (error) {
      dismissToast(toastId);
      errorToast('Error, please try again or contact us in discord')
    }

  }

  useEffect(() => {
    async function findAddress() {
      const addresses = await web3.eth.getAccounts();
      const currentAddress = addresses[0];
      const res = await fetchData(
        ADDRESS_LINKED_URL +
          `publicAddress=${encodeURIComponent(currentAddress)}`
      );
      setLinkedData(res);
    }
    findAddress();
  }, []);


  return (
    <div className="flex-1 flex gap-4 flex-col">
      <div className="flex-1 flex gap-2 flex-col">
        {linkedData?.userName && (
          <span>
            Your Address is linked to the Discord username:{" "}
            <span className="text-purple">{linkedData?.userName}</span>
          </span>
        )}

        <div className="flex gap-2">
          <input
            className="border-gray outline-none flex-1 bg-dark rounded px-2"
            onChange={(e) => setUserName(e.target.value)}
            placeholder="Enter username including the number"
          />
          <button className="bg-dark px-4 rounded" onClick={handleGetRole}>
            Get Delegator Role
          </button>
        </div>
      </div>
      <div className="flex flex-col gap-2 mt-4">
        Please copy all the username including the number.<br/>
        Example: "Mr.Sir#4966"
        <img alt="example" src={Example} className="w-full" />
      </div>
    </div>
  );
}
